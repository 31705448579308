import { CognitoRefreshToken } from "amazon-cognito-identity-js";
import { getCognitoUserPoolWrapper } from "../Config/CognitoConfig";
import axiosAPIGatewayClient from "./axiosAPIGatewayClient";
import { isValidString, parseBoolean } from "./Helpers";

/**
 * Save cognito jwt tokens to localStorage
 */
export const setCognitoTokens = (accessToken, refreshToken) => {
  localStorage.setItem("COGNITO_ACCESS_TOKEN", accessToken);
  localStorage.setItem("COGNITO_REFRESH_TOKEN", refreshToken);
};

/**
 * Retrieve cognito jwt tokens from localStorage
 */
export const getCognitoJWTTokens = () => {
  const accessToken = localStorage.getItem("COGNITO_ACCESS_TOKEN");
  const refreshToken = localStorage.getItem("COGNITO_REFRESH_TOKEN");

  return { accessToken, refreshToken };
};

/**
 * Checks if max auth is enabled from config and also checks if the max domain is set
 * If both of them are enabled returns true
 * @returns {boolean}
 */
export const isMaxAuthEnabled = () => {
  const isMaxAuthEnabled = parseBoolean(window?.config?.MAX_AUTH_ENABLED || false);
  const maxDomain = window?.config?.TPH_MAX_DOMAIN;
  return isValidString(maxDomain) && isMaxAuthEnabled;
};

/**
 * Sets the domain cookies that are required by TPH MAX
 * Note that this will fail when the domain DPF is currently running is not a subdomain or apex domain
 * Which can happen during development in local if not properly configured
 * @param {string} accessToken
 * @param {string} refreshToken
 * @returns {boolean} If the domain cookies are set successfully based on if the values exists.
 */
const setDomainCookies = (accessToken, refreshToken) => {
  const domain = '.digitalpaperflow.com';
  const cookiePairs = [
    {
      name: "DPF_PRODUCT_ACCESS_TOKEN",
      value: accessToken,
    },
    {
      name: "DPF_PRODUCT_REFRESH_TOKEN",
      value: refreshToken,
    },
    {
      name: "DPF_API_URL",
      value: localStorage.getItem("API_URL"),
    },
    {
      name: "DPF_API_GW_URL",
      value: localStorage.getItem("API_GATEWAY_URL"),
    },
    {
      name: "DPF_COGNITO_URL",
      value: localStorage.getItem("COGNITO_PROXY_URL"),
    },
    {
      name: "DPF_WEBSOCKET_URL",
      value: localStorage.getItem("WEBSOCKET_API_URL"),
    },
    {
      name: "DPF_SELECTED_PRODUCTION",
      value: localStorage.getItem("loginProduction"),
    },
    {
      name: "DPF_IS_COGNITO_ENABLED",
      value: localStorage.getItem('isCognito')
    },
    {
      name: 'DPF_COGNITO_ACCESS_TOKEN',
      value: localStorage.getItem('COGNITO_ACCESS_TOKEN')
    },
    {
      name: 'DPF_COGNITO_REFRESH_TOKEN',
      value: localStorage.getItem('COGNITO_REFRESH_TOKEN')
    }
  ];
  let isAllValidValues = true;
  for (let index = 0; index < cookiePairs.length; index++) {
    const cookiePair = cookiePairs[index];
    if (cookiePair.value === null || cookiePair.value === undefined) {
      isAllValidValues = false;
      console.log(`Cookie value for ${cookiePair.name} is null`);
    }
    document.cookie = `${cookiePair.name}=${cookiePair.value}; path=/; domain=${domain}; secure`;
  }
  return isAllValidValues;
}

/**
 * Save product jwt tokens to localStorage
 */
export const setProductJWTTokens = (accessToken, refreshToken) => {
  localStorage.setItem("PRODUCT_ACCESS_TOKEN", accessToken);
  localStorage.setItem("PRODUCT_REFRESH_TOKEN", refreshToken);
  if (accessToken && refreshToken && isMaxAuthEnabled()) {
    const isCookiesSet = setDomainCookies(accessToken, refreshToken);
    console.log(isCookiesSet ? 'Set max auth domain cookie' : 'Unable to set max auth domain cookie');
  }
  console.log('Setting access and refresh token')
};

/**
 * Retrieve product jwt tokens from localStorage
 */
export const getProductJWTTokens = () => {
  const accessToken = localStorage.getItem("PRODUCT_ACCESS_TOKEN");
  const refreshToken = localStorage.getItem("PRODUCT_REFRESH_TOKEN");

  return { accessToken, refreshToken };
};

/**
 * Retrieves a new set of product jwt tokens by using the existing product refresh token.
 * Will throw an exception if the refresh request fails
 */
export const refreshProductJWTTokens = async () => {
  // try to refresh product access token
  const { refreshToken: oldRefreshToken } = getProductJWTTokens();
  if (!oldRefreshToken) {
    throw new Error("Invalid refresh token");
  }

  const refreshTokenResponse = await axiosAPIGatewayClient.post(
    "/auth/refresh-token",
    {
      refreshToken: oldRefreshToken,
    }
  );

  const { accessToken, refreshToken } = refreshTokenResponse.data;

  setProductJWTTokens(accessToken, refreshToken);
};

/**
 * Refresh the current cognito session
 * Will throw an exception if the refresh fails
 */
export const refreshCognitoJWTTokens = async () => {
  const { refreshToken: oldCognitoRefreshToken } = getCognitoJWTTokens();
  if (!oldCognitoRefreshToken) {
    return Promise.reject(new Error("Invalid refresh token"));
  }

  const token = new CognitoRefreshToken({
    RefreshToken: oldCognitoRefreshToken,
  });
  const userPool = getCognitoUserPoolWrapper();
  const cognitoUser = userPool.getCurrentUser();

  if (!cognitoUser) {
    return Promise.reject(new Error("Session is expired"));
  }

  cognitoUser.refreshSession(token, (err, session) => {
    if (err) {
      return Promise.reject(new Error("Session is expired"));
    }

    const accessToken = session.getIdToken().getJwtToken();
    const refreshToken = session.getRefreshToken().getToken();
    setCognitoTokens(accessToken, refreshToken);
    return Promise.resolve();
  });
};
