import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axiosDPFAPIClient from "../../../../../Utils/axiosDPFAPIClient";
import axios from "axios";
import { getToastMessage, isFailedRequest } from "../../../../../Utils/Helpers";
import { toast } from "react-toastify";
import "./CopyApproverGroup.css";

function RenderErrorText({ text }) {
  return text ? <p className="text-error">{text}</p> : <></>;
}


export default function CopyApprovalGroup({
  onClose = () => { },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isInitalLoad, setIsInitialLoad] = useState(false);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);

  const [approvalGroupName, seApprovalGroupName] = useState("");
  /**
   * For the Checkboxes that allows the user to copy module
   * @type {[PrimeCopyApprovalGroupModules[], function(PrimeCopyApprovalGroupModules[]): void]}
   */
  const [modules, setModules] = useState([]);
  /**
   * For the drop down
   * @type {[PrimeCopyApprovalGroups[], function(PrimeCopyApprovalGroups[]): void]}
   */
  const [approvalGroups, setApprovalGroups] = useState([]);
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  const handleClose = (event) => {
    seApprovalGroupName(() => "");
    setModules(() => []);
    setApprovalGroups(() => []);
    setSelectedRecordId(() => null);
    setIsLoading(() => false);
    setIsInitialLoad(() => false);
    setIsSubmitAttempted(() => false);
    onClose(event);
  };

  const handleSave = async () => {
    setIsSubmitAttempted(() => true);
    if (!approvalGroupName) {
      toast.error("Enter the name of new Approval group");
      return;
    }
    if (!selectedRecordId) {
      toast.error("Select an approver to copy from");
      return;
    }
    setIsLoading(() => true);
    const isCopied = await copyApproverGroup();
    setIsLoading(() => false);
    if (isCopied) {
      handleClose();
    }
  };

  /**
   * Gets the prime approver group for copying
   * @returns {Promise<PrimeCopyApprovalGroupResponse>}
   */
  const primeApproverGroupForCopy = async () => {
    const url = '/DPFAPI/UserRequest?actionType=PrimeCopyApprovalGroup';
    const result = {
      approvalGroups: [],
      modules: [],
    };
    const response = await axiosDPFAPIClient.get(url)
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const errorResponse = error.response?.data?.UserResponse || { results: [{ status: "Failed", description: "Failed to copy approval group!" }] };
          const errorMessage = getToastMessage(errorResponse);
          toast.error(errorMessage);
          return result;
        }
        console.log('Error while copying approval group:', error);
        toast.error("Failed to copy approval group!");
        return result;
      });
    if (isFailedRequest(response?.data?.UserResponse)) {
      const errorMessage = getToastMessage(response?.data?.UserResponse);
      toast.error(errorMessage);
      onClose();
      return result;
    }
    const approvalGroups = response?.data?.UserResponse?.approvalGroups || [];
    const modules = response?.data?.UserResponse?.modules || [];
    result.approvalGroups = approvalGroups;
    result.modules = modules;
    return result;
  }

  const onModuleSelect = (e, module) => {
    const newModules = modules.map((m) => {
      if (m.module === module) {
        return { ...m, selected: e.target.checked ? 'Y' : 'N' };
      }
      return m;
    });
    setModules(newModules);
  }

  const renderForm = () => {
    return (
      <div className="modal-body">
        { isLoading ? <div className="transparent-loader"></div> : null }
        <Row>
          <Col>
            <label>Old Approval Group <span className="text-error">*</span></label>
            <select
              className="form-control"
              value={selectedRecordId}
              onChange={(e) => { setSelectedRecordId(e.target.value) }}
              required
              disabled={isLoading}
            >
              <option value="">Select Approval Group</option>
              {approvalGroups.map((approvalGroup) => (
                <option key={approvalGroup.recordID} value={approvalGroup.recordID}>{approvalGroup.approvalName}</option>
              ))}
            </select>
            <RenderErrorText text={selectedRecordId || !isSubmitAttempted ? null : "Select an approver to copy from"} />
          </Col>
          <Col>
            <label htmlFor="newApprovalGroupName">New Approval Group Name <span className="text-error">*</span></label>
            <input
              type="text"
              name="newApprovalGroupName"
              className="form-control"
              value={approvalGroupName}
              required
              disabled={isLoading}
              onChange={(e) => { seApprovalGroupName(String(e.target.value).toUpperCase()) }}
            />
            <RenderErrorText text={approvalGroupName || !isSubmitAttempted ? null : "Enter the name of new Approval group"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <table className="table modules-table mt-4" width="100%">
              <thead className="thead_bg hover-border">
                <tr>
                  <th scope="col" className="text-white font-weight-800">Module</th>
                  <th scope="col" className="text-white font-weight-800">
                    Selected
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>All modules</td>
                  <td>
                    <input
                      type="checkbox"
                      className="checkbox-medium"
                      checked={modules.every(({ selected }) => selected === 'Y')}
                      onChange={(e) => {
                        const newModules = modules.map((module) => ({ ...module, selected: e.target.checked ? 'Y' : 'N' }));
                        setModules(newModules);
                      }}
                      disabled={isLoading}
                    />
                  </td>
                </tr>
                {modules?.map(({ module, selected }, index) => (
                  <tr key={index}>
                    <td>{module}</td>
                    <td>
                      <input
                        type="checkbox"
                        className="checkbox-medium"
                        checked={selected === 'Y'}
                        onChange={(e) => { onModuleSelect(e, module) }}
                        disabled={isLoading}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    )
  }

  const copyApproverGroup = async () => {
    const url = `/DPFAPI/UserRequest`;
    const body = {
      actionType: "CopyApprovalGroup",
      approvalName: approvalGroupName,
      recordID: selectedRecordId,
      modules: modules.map((module) => ({ module: module.module, selected: module.selected })),
    }
    const response = await axiosDPFAPIClient.post(url, body);
    if (isFailedRequest(response?.data?.UserResponse)) {
      const errorMessage = getToastMessage(response?.data?.UserResponse);
      toast.error(errorMessage);
      return false;
    };
    toast.success("Approval group copied successfully!");
    return true;
  }

  useEffect(() => {
    setIsInitialLoad(true);
    primeApproverGroupForCopy().then((response) => {
      setApprovalGroups(response.approvalGroups);
      setModules(response.modules);
    }).finally(() => {
      setIsInitialLoad(false);
    });
  }, []);

  return (
    <>
      <Modal size="lg" centered show={true} className="copy-approval-group">
        <Modal.Body className="p-0">
          <div className="container-fluid p-0">
            <div className="main_wrapper">
              <div className="row d-flex h-100 p-0">
                <div className="col-12 justify-content-center align-self-center">
                  <div className="p-0">
                    <div className="setting_header thead_bg">
                      <div className="setting_header thead_bg">
                        <h3 className="Indirecttaxcode-poup_heading">Copy Approval Group</h3>
                        <div className="Indirecttaxcode-poup_can-sav-btn">
                          <button
                            className="btn can-btn1"
                            onClick={handleSave}
                            disabled={isLoading}
                          >
                            <img
                              src="images/user-setup/check-white.png"
                              alt="check"
                            />
                            Save
                          </button>
                          <button className="btn can-btn1 pl-3" onClick={handleClose} disabled={isLoading}>
                            <img
                              src="images/user-setup/cancel-white.png"
                              alt="cancel"
                            />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="modal-input-row">
                      { isInitalLoad ? (
                        <div className="transparent-loader"></div>
                      ) : renderForm()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}


/**
 * @typedef {Object} PrimeCopyApprovalGroupResponse
 * @property {PrimeCopyApprovalGroups[]} approvalGroups
 * @property {PrimeCopyApprovalGroupModules[]} modules
 */

/**
 * @typedef {Object} PrimeCopyApprovalGroups
 * @property {string} approvalName The name of the approval group
 * @property {string} recordID The record ID of the approval group
 */

/**
 * @typedef {Object} PrimeCopyApprovalGroupModules
 * @property {string} module The name of the module
 * @property {'Y'|'N'} selected string value says if the module is selected or not
 * @property {number} tally total number of tallies, which won't be used here
 */